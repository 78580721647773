import log from "loglevel";
import { toast } from "react-toastify";

import Api from "@api/api";
import { RootStore } from "./root-store";

const { makeAutoObservable } = require("mobx");


class MainStore {

    private rootStore: RootStore;

    private _statistic = {};

    get statistic() {
        return this._statistic;
    }
    set statistic(val: any) {
        this._statistic = val;
    }


    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async getStatistic() {
        log.debug("getStatistic");
        try {
            const res = await Api.statistic();
            log.debug(res);
            if (res.data.status === 'OK') {
                this.statistic = res.data.statistic;
            }

        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
        }
    }

}

export default MainStore;