import log from "loglevel";
import React, {useEffect} from 'react';
import { Link, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";

import logo from "@assets/logo.svg";
import { useStore } from "@hooks/use-store";
import { TypesAdmin } from "../../types/types-admin";
import styles from './sidebar.module.scss';

import Menu from "@config/menu";

const Sidebar = () => {

    const { authStore } = useStore();

    useEffect(() => {
        // @ts-ignore
        setTimeout(() => feather.replace(), 300);
    }, []);

    const location = useLocation();

    const onSidebarLinkClick = () => {
        log.debug("onSidebarLinkClick");
        // @ts-ignore
        window.toggleSidebar();
    }
    
    const menu = Menu.map((row: any, i: number) => {
        return (
            <div key={i}>
                {(row.admin == 0 || authStore.type == TypesAdmin.Admin) &&
                    <li
                        className={"sidebar-item " + (row.link === location.pathname ? "active" : "")}>
                        <Link className="sidebar-link" to={row.link} onClick={onSidebarLinkClick}>
                            <i className="align-middle" data-feather={row.icon}/>
                            <span className="align-middle">{row.title}</span>
                        </Link>
                    </li>
                }
            </div>
        )
    });

    return (
        <nav id="sidebar" className="sidebar js-sidebar">
        <div className="sidebar-content js-simplebar pt-1">
            <a className={"sidebar-brand " + styles.logo} href="/">
                <span className="align-middle"><img src={logo}/></span>
            </a>

            <ul className="sidebar-nav">
                {menu}

                {authStore.user_id == 25 &&
                    <li
                        className={"sidebar-item " + (location.pathname === '/reset' ? "active" : "")}>
                        <Link className="sidebar-link" to='/reset' onClick={onSidebarLinkClick}>
                            <i className="align-middle" data-feather="thumbs-down"/>
                            <span className="align-middle">Очистка</span>
                        </Link>
                    </li>
                }
            </ul>
        </div>
    </nav>
    );
};

export default observer(Sidebar);
