import moment from "moment";
import { observer } from "mobx-react-lite";
import React from 'react';
import { FloatingLabel, Form } from "react-bootstrap";

import styles from "./event-details.module.scss";
import {useStore} from "@hooks/use-store";

export type EventDetailsProps = {
    class?: string
    event: any
    onEditClick: any
    onSubscribersClick: any
    onRegisterClick: any
    subscribers: any[]
}

const EventDetails = (props: EventDetailsProps) => {

    const { authStore } = useStore();

    const eventDateTime = () => {
        return moment(props.event.date).format('DD.MM.YYYY') + " " + props.event.time;
    }

    return (
        <Form>
            <div className="d-flex justify-content-between">
                <h3>Детали мероприятия</h3>
                <div className="d-flex gap-3">
                    {authStore.type == 1 &&
                        <div className={styles.edit} title="Редактировать" onClick={props.onEditClick}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>Изменить</title><path d="M18.13 12L19.39 10.74C19.83 10.3 20.39 10.06 21 10V9L15 3H5C3.89 3 3 3.89 3 5V19C3 20.1 3.89 21 5 21H11V19.13L11.13 19H5V5H12V12H18.13M14 4.5L19.5 10H14V4.5M19.13 13.83L21.17 15.87L15.04 22H13V19.96L19.13 13.83M22.85 14.19L21.87 15.17L19.83 13.13L20.81 12.15C21 11.95 21.33 11.95 21.53 12.15L22.85 13.47C23.05 13.67 23.05 14 22.85 14.19Z" /></svg>
                        </div>
                    }
                    <div className={styles.edit} title="Участники" onClick={props.onSubscribersClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16 17V19H2V17S2 13 9 13 16 17 16 17M12.5 7.5A3.5 3.5 0 1 0 9 11A3.5 3.5 0 0 0 12.5 7.5M15.94 13A5.32 5.32 0 0 1 18 17V19H22V17S22 13.37 15.94 13M15 4A3.39 3.39 0 0 0 13.07 4.59A5 5 0 0 1 13.07 10.41A3.39 3.39 0 0 0 15 11A3.5 3.5 0 0 0 15 4Z" /></svg>
                        <div className={styles.subscribers}>{props.subscribers.length}</div>
                    </div>
                    <div className={styles.edit} title="Регистрация" onClick={props.onRegisterClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3,11H5V13H3V11M11,5H13V9H11V5M9,11H13V15H11V13H9V11M15,11H17V13H19V11H21V13H19V15H21V19H19V21H17V19H13V21H11V17H15V15H17V13H15V11M19,19V15H17V19H19M15,3H21V9H15V3M17,5V7H19V5H17M3,3H9V9H3V3M5,5V7H7V5H5M3,15H9V21H3V15M5,17V19H7V17H5Z" /></svg>
                    </div>
                </div>
            </div>
            <div className={styles.row}>
                <label>Название</label>
                <div>{props.event.title}</div>
            </div>
            <div className={styles.row}>
                <label>{props.event?.type_id === 1 ? "Место проведения" : "Ссылка"}</label>
                <div>
                    {props.event?.type_id === 1
                        ? props.event.place
                        : <a href={props.event.place} target="_blank">{props.event.place}</a>
                    }
                </div>
            </div>
            <div className={styles.row}>
                <label>Дата и время проведения</label>
                <div>{eventDateTime()}</div>
            </div>
        </Form>
    );
};

export default observer(EventDetails);
