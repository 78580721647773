import cn from "classnames";
import React, { useEffect } from 'react';
import { observer } from "mobx-react-lite";

import { useStore } from "@hooks/use-store";
import chapaev from "@assets/chapaev.jpg";
import styles from "./reset.module.scss";
import { Button } from "react-bootstrap";

const Reset = () => {

    const { authStore } = useStore();

    useEffect(() => {
    }, []);

    const onFireClick = async () => {
        await authStore.reset();
    }

    return (
        <div>
            <h1 className="mb-3">Очистка</h1>

            <div className={cn(styles.chapaev)}>
                <div>
                    <img className="mb-3" src={chapaev}/>
                </div>
                <Button variant="primary" onClick={onFireClick}>Огонь!</Button>
            </div>


        </div>
    );
};

export default observer(Reset);
