import log from "loglevel";
import moment from "moment";
import React, { useEffect } from 'react';
import { observer } from "mobx-react-lite";
import {Col, Row, Table} from "react-bootstrap";
import { useStore } from "@hooks/use-store";
import styles from "./moderation.module.scss";

const Moderation = () => {

    const { moderationStore } = useStore();

    useEffect(() => {
        moderationStore.getUsers().then();
    }, []);


    const onTypeChange = async (type: string) => {
        log.debug("onTypeChange", type);
        moderationStore.type = type;
        await moderationStore.getUsers();
    }

    const onChevronClick = (i: number) => {
        log.debug("onChevronClick", i);
        // @ts-ignore
        moderationStore.users[i].show = moderationStore.users[i].show ? 0 : 1;
    }

    const onApproveClick = async (user_id: number) => {
        log.debug("onApproveClick", user_id);
        await moderationStore.moderation(user_id, 1);
    }
    const onDeclineClick = async (user_id: number) => {
        log.debug("onDeclineClick", user_id);
        await moderationStore.moderation(user_id, 0);
    }

    const rowClass = (approved: number) => {
        switch (approved) {
            case 0:
                return styles.declined;
            case 1:
                return styles.approved;
            default:
                return "";
        }
    }

    const list = moderationStore.users.map((row: any, i: number) => {
        //log.debug("RESULT", toJS(result));
        return (
            <tr key={i}>
                <td className={styles.name + " " + (row.show ? styles.opened : "")} onClick={() => onChevronClick(i)}>
                    <div className="d-flex">
                        <span className={styles.type}>
                            {row.is_doctor
                                ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>Врач</title><path fill="#dc3545" d="M10,3L8,5V7H5C3.85,7 3.12,8 3,9L2,19C1.88,20 2.54,21 4,21H20C21.46,21 22.12,20 22,19L21,9C20.88,8 20.06,7 19,7H16V5L14,3H10M10,5H14V7H10V5M11,10H13V13H16V15H13V18H11V15H8V13H11V10Z" /></svg>
                                : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>Фармацевт/провизор</title><path fill="#1da1f2" d="M16.2 3.5C15.2 2.5 13.9 2 12.7 2S10.1 2.5 9.2 3.5L3.4 9.1C1.4 11.1 1.4 14.2 3.4 16.2S8.5 18.2 10.5 16.2L16.2 10.5C18.1 8.6 18.1 5.4 16.2 3.5M14.8 9.1L12 11.9L8.4 8.4L4 12.8C4 12 4.2 11.1 4.9 10.5L10.6 4.8C11.1 4.3 11.9 4 12.6 4S14.1 4.3 14.7 4.8C15.9 6.1 15.9 7.9 14.8 9.1M19.6 7.1C19.6 7.9 19.4 8.6 19.2 9.4C20.2 10.6 20.2 12.4 19.1 13.5L16.3 16.3L14.8 14.8L12 17.6C10.7 18.9 8.9 19.6 7.2 19.6C7.4 19.9 7.6 20.2 7.9 20.5C9.9 22.5 13 22.5 15 20.5L20.7 14.8C22.7 12.8 22.7 9.7 20.7 7.7C20.2 7.5 19.9 7.3 19.6 7.1Z" /></svg>
                            }
                        </span>
                        <span className={rowClass(row.is_approved)}>
                            {row.lastname} {row.firstname} {row.middlename}
                        </span>
                    </div>
                    <div className={styles.details}>
                        <div>
                            <b>Тел.:</b> <a href={"tel:" + row.phone}>{row.phone}</a>
                        </div>
                        <div>
                            <b>E-mail:</b> <a href={"mailto:" + row.email}>{row.email}</a>
                        </div>
                        <div>
                            <b>Адрес:</b> {row.city}, {row.org_address}
                        </div>
                        <div>
                            <b>Сеть:</b> {row.network} &nbsp; <b>Должность:</b> {row.position}
                        </div>
                        <div>
                            <b>Дата:</b> {moment(row.created_dt).format('DD.MM.YYYY HH:mm:ss')}
                        </div>
                        <div>
                            <b>Баллы:</b> <span className="text-success">{row.points_in ?? 0}</span>/<span className="text-danger">{row.points_out ?? 0}</span> &nbsp;
                            <b>Пройдено модулей:</b> {row.module}
                        </div>
                    </div>
                </td>
                <td className={styles.control + " " + styles.approve} title="Одобрить" valign={"top"}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" onClick={() => onApproveClick(row.user_id)}>
                        <path d="M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z" />
                    </svg>
                </td>
                <td className={styles.control + " " + styles.decline} title="Отклонить">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" onClick={() => onDeclineClick(row.user_id)}>
                        <path d="M19,3H16.3H7.7H5A2,2 0 0,0 3,5V7.7V16.4V19A2,2 0 0,0 5,21H7.7H16.4H19A2,2 0 0,0 21,19V16.3V7.7V5A2,2 0 0,0 19,3M15.6,17L12,13.4L8.4,17L7,15.6L10.6,12L7,8.4L8.4,7L12,10.6L15.6,7L17,8.4L13.4,12L17,15.6L15.6,17Z" />
                    </svg>
                </td>
            </tr>
        )
    });


    return (
        <div>
            <Row>
                <Col md={8} xs={6}>
                    <h1 className="mb-3">Участники</h1>
                </Col>
                <Col>
                    <select className="form-select" onChange={(e: any) => onTypeChange(e.target.value)}>
                        <option value="">Все</option>
                        <option value="-1" selected>На модерации</option>
                        <option value="1">Одобрены</option>
                        <option value="0">Отклонены</option>
                    </select>
                </Col>
            </Row>
            <Table striped bordered hover className={styles.table}>
                <tbody>
                    {list}
                </tbody>
            </Table>

            <Row className={styles.legend}>
                <Col xs={7}>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>Врач</title><path fill="#dc3545" d="M10,3L8,5V7H5C3.85,7 3.12,8 3,9L2,19C1.88,20 2.54,21 4,21H20C21.46,21 22.12,20 22,19L21,9C20.88,8 20.06,7 19,7H16V5L14,3H10M10,5H14V7H10V5M11,10H13V13H16V15H13V18H11V15H8V13H11V10Z" /></svg>
                        Врач
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>Фармацевт/провизор</title><path fill="#1da1f2" d="M16.2 3.5C15.2 2.5 13.9 2 12.7 2S10.1 2.5 9.2 3.5L3.4 9.1C1.4 11.1 1.4 14.2 3.4 16.2S8.5 18.2 10.5 16.2L16.2 10.5C18.1 8.6 18.1 5.4 16.2 3.5M14.8 9.1L12 11.9L8.4 8.4L4 12.8C4 12 4.2 11.1 4.9 10.5L10.6 4.8C11.1 4.3 11.9 4 12.6 4S14.1 4.3 14.7 4.8C15.9 6.1 15.9 7.9 14.8 9.1M19.6 7.1C19.6 7.9 19.4 8.6 19.2 9.4C20.2 10.6 20.2 12.4 19.1 13.5L16.3 16.3L14.8 14.8L12 17.6C10.7 18.9 8.9 19.6 7.2 19.6C7.4 19.9 7.6 20.2 7.9 20.5C9.9 22.5 13 22.5 15 20.5L20.7 14.8C22.7 12.8 22.7 9.7 20.7 7.7C20.2 7.5 19.9 7.3 19.6 7.1Z" /></svg>
                        Фармацевт/провизор
                    </div>
                </Col>
                <Col xs={5} className={styles.types}>
                    <div className={styles.moderation}><span></span> На модерации</div>
                    <div className={styles.approved}><span></span> Одобрен</div>
                    <div className={styles.declined}><span></span> Отклонен</div>
                </Col>
            </Row>

        </div>
    );
};

export default observer(Moderation);
