import log from "loglevel";
import { instance } from "./service";

class Api {

    users(type: string) {
        log.debug("users");
        return instance.post("/users", {
            type: type
        });
    }

    moderation(user_id: number, state: number) {
        log.debug("moderation");
        return instance.post("/moderation", {
            user_id: user_id,
            state: state
        });
    }

    events(type: number) {
        log.debug("events type", type);
        return instance.post("/events", {
            type: type
        });
    }

    save_event(event: any) {
        log.debug("events", event);
        return instance.post("/event", event);
    }

    delete_event(event_id: number) {
        log.debug("event_id", event_id);
        return instance.delete("/event/" + event_id);
    }

    subscribers(event_id: number) {
        log.debug("subscribers event_id", event_id);
        return instance.get("/subscribers/" + event_id);
    }

    unsubscribe(event_id: number, user_id: number) {
        log.debug(`unsubscribe event_id:${event_id} user_id:${user_id}`);
        return instance.delete(`/unsubscribe/${event_id}/${user_id}`);
    }

    reviews() {
        log.debug("reviews");
        return instance.get("/reviews");
    }

    moderation_review(review_id: number, state: number) {
        log.debug("moderation_review");
        return instance.post("/review/moderation", {
            review_id: review_id,
            state: state
        });
    }

    statistic() {
        log.debug("statistic");
        return instance.get("/statistic");
    }

    find_user(hash: string, event_id: number) {
        log.debug("find_user hash", hash);
        return instance.post("/find_user", {
            hash: hash,
            event_id: event_id
        });
    }

    came(event_id: number, user_id: number) {
        log.debug(`came event_id:${event_id} user_id:${user_id}`);
        return instance.delete(`/came/${event_id}/${user_id}`);
    }

}

export default new Api();
