import log from "loglevel";
import Cookie from 'mobx-cookie';
import { toast } from "react-toastify";
import { makeAutoObservable } from "mobx";

import { RootStore } from "./root-store";
import Api from "@api/auth";

/**
 * Стор авторизации для страниц login
 */
class AuthStore {

    private rootStore: RootStore;

    private _authenticated: boolean = false;
    public user?: string|null = null;
    public token?: string|null = null;
    public _type = 0;
    public _user_id = 0;

    public cookie = new Cookie('token');

    get type() {
        return this._type;
    }
    set type(val) {
        this._type = val;
    }

    get user_id() {
        return this._user_id;
    }
    set user_id(val) {
        this._user_id = val;
    }

    constructor(rootStore: any) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    get authenticated() {
        return this._authenticated;
    }
    set authenticated(val) {
        this._authenticated = val;
    }

    isAuth() {
        const auth = localStorage.getItem("token") != null;
        return this.authenticated = auth;
    }

    setToken(token: string, refresh_token: string) {
        this.token = token;
        localStorage.setItem("token", token);
        localStorage.setItem("refresh_token", refresh_token);
        this.cookie.set(token);
    }

    async login(email: string, passwd: string) {
        log.debug("login email", email, passwd);
        try {
            const res = await Api.login(email, passwd);
            console.log("ADMIN", res);
            if (res.data.status === 'OK') {
                this.setToken(res.data.token, res.data.refresh_token);
                this.type = res.data.type;
            }
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    logout() {
        log.debug("logout");
        this.token = null;
        this.authenticated = false;
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("token");
        document.location.href = "/login";
    }

    async getUser() {
        log.debug("getUser");
        try {
            const res = await Api.user();
            console.log("ADMIN", res);
            if (res.data.status === 'OK') {
                this.type = res.data.type;
                this.user_id = res.data.user_id;
            }
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async reset() {
        log.debug("reset");
        try {
            const res = await Api.reset();
            if (res.data.status === 'OK') {
                toast.success("Очищено");
            }
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
        }
    }

}

export default AuthStore;