import log from "loglevel";
import { toast } from "react-toastify";
import { RootStore } from "./root-store";
import Api from "@api/api";
import moment from "moment";

const { makeAutoObservable } = require("mobx");


class TrainingStore {

    private rootStore: RootStore;

    private _selectedData = "";
    private _events: any = [];
    private _event: any;
    private _type = 1;
    private _modalShow = false;
    private _modalSubscribersShow = false;
    private _modalRegisterShow = false;
    private _subscribers: any = [];
    private _stopDecoding = false;


    // region GETTERS & SETTERS
    get selectedData() {
        return this._selectedData;
    }
    set selectedData(val: string) {
        this._selectedData = val;
    }

    get events() {
        return this._events;
    }
    set events(val: any[]) {
        this._events = val;
    }

    get event() {
        return this._event;
    }
    set event(val: any) {
        this._event = val;
    }

    get type() {
        return this._type;
    }
    set type(val: number) {
        this._type = val;
    }

    get modalShow() {
        return this._modalShow;
    }
    set modalShow(val: boolean) {
        this._modalShow = val;
    }

    get modalRegisterShow() {
        return this._modalRegisterShow;
    }
    set modalRegisterShow(val: boolean) {
        this._modalRegisterShow = val;
    }

    get modalSubscribersShow() {
        return this._modalSubscribersShow;
    }
    set modalSubscribersShow(val: boolean) {
        this._modalSubscribersShow = val;
    }

    get subscribers() {
        return this._subscribers;
    }
    set subscribers(val: any[]) {
        this._subscribers = val;
    }

    get stopDecoding() {
        return this._stopDecoding;
    }
    set stopDecoding(val: boolean) {
        this._stopDecoding = val;
    }
    // endregion


    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async getEvents() {
        log.debug("getEvents");
        try {
            const res = await Api.events(this.type);
            log.debug(res);
            if (res.data.status === 'OK') {
                this.events = res.data.events;
                for (let i in this.events) {
                    this.events[i].date = moment(this.events[i].date).format('YYYY-MM-DD');
                }
            }
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async save() {
        log.debug("save");
        try {
            const res = await Api.save_event({
                event_id: this.event.event_id,
                type_id: this.type,
                title: this.event.title,
                place: this.event.place,
                date: this.event.date,
                time: this.event.time,
                rem: this.event.rem ?? ""
            });
            log.debug(res);
            if (res.data.status === 'OK') {
                return true;
            } else {
                toast.error(res?.data.message);
            }
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
        }
        return false;
    }

    async delete() {
        log.debug("delete");
        try {
            const res = await Api.delete_event(this.event.event_id);
            log.debug(res);
            if (res.data.status === 'OK') {
                return true;
            } else {
                toast.error(res?.data.message);
            }
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
        }
        return false;
    }

    async getSubscribers() {
        log.debug("getSubscribers", this.event);
        try {
            const res = await Api.subscribers(this.event.event_id);
            log.debug(res);
            if (res.data.status === 'OK') {
                this.subscribers = res.data.subscribers ?? [];
            }
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async unsubscribe(user_id: number) {
        log.debug("unsubscribe user_id", user_id);
        try {
            const res = await Api.unsubscribe(this.event.event_id, user_id);
            log.debug(res);
            if (res.data.status === 'OK') {
                return true;
            } else {
                toast.error(res?.data.message);
            }
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
        }
        return false;
    }

    async findUser(hash: string, event_id: number) {
        log.debug("findUser hash", hash);
        let result;
        try {
            const res = await Api.find_user(hash, event_id);
            log.debug(res);
            result = res.data;
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
        }
        return result;
    }

    async came(user_id: number) {
        log.debug("came user_id", user_id);
        try {
            const res = await Api.came(this.event.event_id, user_id);
            log.debug(res);
            if (res.data.status === 'OK') {
                return true;
            } else {
                toast.error(res?.data.message);
            }
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
        }
        return false;
    }

}

export default TrainingStore;