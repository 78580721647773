import React, { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";

import { TypesAdmin } from "../types/types-admin";
import { useStore } from "@hooks/use-store";
import NotFound from "@layouts/not-found/NotFound";
import Main from "@views/main/main";
import Moderation from "@views/moderation/moderation";
import Reviews from "@views/reviews/reviews";
import Trainings from "@views/trainings/trainings";
import Reset from "@views/reset/reset";

const MainRoutes = (props: any) => {

    const { authStore } = useStore();

    useEffect(() => {
        authStore.getUser().then(() => {});
    }, [ authStore.type ]);

    return (
        <Routes>
            {authStore.type == TypesAdmin.Admin
                ? <>
                    <Route index element={<Navigate to="/main" replace />} />
                    <Route path="main" element={<Main/>} />
                    <Route path="moderation" element={<Moderation/>} />
                    <Route path="reviews" element={<Reviews/>} />
                    <Route path="trainings" element={<Trainings/>} />
                    <Route path="reset" element={<Reset/>} />
                    <Route path='*' element={<NotFound/>} />
                </>
                : <Route path='*' element={<Trainings/>} />
            }
        </Routes>
    );
}

export default observer(MainRoutes);