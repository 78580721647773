import React from 'react';
import { observer } from "mobx-react-lite";

import {useStore} from "@hooks/use-store";


const Logout = () => {

    const { authStore } = useStore();

    authStore.logout();

    return <div/>;

};

export default observer(Logout);
