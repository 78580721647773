import log from "loglevel";
import { toast } from "react-toastify";
import { makeAutoObservable } from "mobx";

import { RootStore } from "./root-store";
import Api from "@api/api";


class ModerationStore {

    private rootStore: RootStore;

    private _users = [];
    private _type = "-1";

    get users() {
        return this._users;
    }
    set users(val) {
        this._users = val;
    }

    get type() {
        return this._type;
    }
    set type(val) {
        this._type = val;
    }


    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async getUsers() {
        log.debug("getUsers");
        try {
            const res = await Api.users(this.type);
            log.debug(res);
            if (res.data.status === 'OK') {
                this.users = res.data.users;
            }
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async moderation(user_id: number, state: number) {
        log.debug("moderation");
        try {
            const res = await Api.moderation(user_id, state);
            log.debug(res);
            if (res.data.status === 'OK') {
                state
                    ? toast.success("Одобрен")
                    : toast.error("Отклонен");
                await this.getUsers();
            }
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
        }
    }

}

export default ModerationStore;