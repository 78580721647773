import log from "loglevel";
import { RootStore } from "./root-store";

const { makeAutoObservable } = require("mobx");


class LoginStore {

    private rootStore: RootStore;

    public data = {
        email: "",
        password: "",
    }

    get email() {
        return this.data.email;
    }
    set email(email: string) {
        this.data.email = email;
    }

    get password() {
        return this.data.password;
    }
    set password(password: string) {
        this.data.password = password;
    }

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async login() {
        log.debug("sendLoginForm");
        await this.rootStore.authStore.login(this.email, this.password);
    }

}

export default LoginStore;