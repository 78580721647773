import log from "loglevel";
import React, {ReactNode, useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Button, Col, Form, Row } from "react-bootstrap";

import { useStore } from "@hooks/use-store";
import logo from "@assets/logo1.svg";
import styles from "./login.module.scss";


const Login = () => {

    const navigate = useNavigate();
    const [ showPasswd, setShowPasswd ] = useState(false);
    const { authStore, loginStore } = useStore();

    useEffect(() => {
        if (authStore.isAuth()) {
            navigate("/");
        }
    }, [ authStore.token ]);
    useEffect(() => {
    }, [ showPasswd ]);

    const onEmailChange = (e: any) => {
        loginStore.email = e.target.value;
    }
    const onPasswordChange = (e: any) => {
        loginStore.password = e.target.value;
    }

    const onShowPasswdClick = () => {
        log.debug("onShowPasswdClick", showPasswd);
        setShowPasswd(!showPasswd);
    }

    const onSubmit = async (e: any) => {
        e.preventDefault();
        await loginStore.login();
    }

    const showPasswdIcon = () => {
        return showPasswd
            ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M2,5.27L3.28,4L20,20.72L18.73,22L15.65,18.92C14.5,19.3 13.28,19.5 12,19.5C7,19.5 2.73,16.39 1,12C1.69,10.24 2.79,8.69 4.19,7.46L2,5.27M12,9A3,3 0 0,1 15,12C15,12.35 14.94,12.69 14.83,13L11,9.17C11.31,9.06 11.65,9 12,9M12,4.5C17,4.5 21.27,7.61 23,12C22.18,14.08 20.79,15.88 19,17.19L17.58,15.76C18.94,14.82 20.06,13.54 20.82,12C19.17,8.64 15.76,6.5 12,6.5C10.91,6.5 9.84,6.68 8.84,7L7.3,5.47C8.74,4.85 10.33,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C12.69,17.5 13.37,17.43 14,17.29L11.72,15C10.29,14.85 9.15,13.71 9,12.28L5.6,8.87C4.61,9.72 3.78,10.78 3.18,12Z" /></svg>
            : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C21.27,16.39 17,19.5 12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C15.76,17.5 19.17,15.36 20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12Z" /></svg>;
    }

    return (
        <Row className="h-100 w-100 d-flex align-items-center justify-content-center p-0">
            <Col xs={12} md={6} sm={8} lg={6} xl={4} className="ps-4">
                <Form className="ps-2" onSubmit={onSubmit}>
                    <div className="text-center mb-5">
                        <img src={logo}/>
                    </div>
                    <Form.Group className="mb-3" controlId="login">
                        <Form.Label>E-mail</Form.Label>
                        <Form.Control
                            className="text-center"
                            type="email"
                            placeholder="name@example.com"
                            required
                            size="lg"
                            onChange={onEmailChange}
                        />
                    </Form.Group>
                    <Form.Group className={"mb-3 " + styles.passwd} controlId="password">
                        <Form.Label>Пароль</Form.Label>
                        <Form.Control
                            className="text-center"
                            type={ showPasswd ? "text" : "password"}
                            placeholder="Пароль"
                            required
                            size="lg"
                            onChange={onPasswordChange}
                        />
                        <div className={styles.show_password} onClick={onShowPasswdClick}>
                            {showPasswdIcon()}
                        </div>
                    </Form.Group>

                    <Button
                        type="submit"
                        className="w-100 mt-2"
                        variant="primary"
                        size="lg"
                    >
                        Отправить
                    </Button>
                </Form>
            </Col>
        </Row>
    );

};

export default observer(Login);
