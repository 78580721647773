import log from "loglevel";
import cn from "classnames";
import React from 'react';
import { observer } from "mobx-react-lite";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import styles from "./event-subscribers.module.scss";
import {useStore} from "@hooks/use-store";


export type EventSubscribersProps = {
    class?: string
    show: boolean
    type: number
    subscribers: any
    handleClose: any
    onCameClick: any
    onUnsubscribeClick: any
}

const EventSubscribers = (props: EventSubscribersProps) => {

    const { authStore } = useStore();

    const list = props.subscribers?.map((user: any, i: number) => {
        return (
            <div key={i} className={styles.subscriber}>
                <div className="d-flex">
                    {user.is_doctor
                        ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>Врач</title><path fill="#dc3545" d="M10,3L8,5V7H5C3.85,7 3.12,8 3,9L2,19C1.88,20 2.54,21 4,21H20C21.46,21 22.12,20 22,19L21,9C20.88,8 20.06,7 19,7H16V5L14,3H10M10,5H14V7H10V5M11,10H13V13H16V15H13V18H11V15H8V13H11V10Z" /></svg>
                        : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>Фармацевт/провизор</title><path fill="#1da1f2" d="M16.2 3.5C15.2 2.5 13.9 2 12.7 2S10.1 2.5 9.2 3.5L3.4 9.1C1.4 11.1 1.4 14.2 3.4 16.2S8.5 18.2 10.5 16.2L16.2 10.5C18.1 8.6 18.1 5.4 16.2 3.5M14.8 9.1L12 11.9L8.4 8.4L4 12.8C4 12 4.2 11.1 4.9 10.5L10.6 4.8C11.1 4.3 11.9 4 12.6 4S14.1 4.3 14.7 4.8C15.9 6.1 15.9 7.9 14.8 9.1M19.6 7.1C19.6 7.9 19.4 8.6 19.2 9.4C20.2 10.6 20.2 12.4 19.1 13.5L16.3 16.3L14.8 14.8L12 17.6C10.7 18.9 8.9 19.6 7.2 19.6C7.4 19.9 7.6 20.2 7.9 20.5C9.9 22.5 13 22.5 15 20.5L20.7 14.8C22.7 12.8 22.7 9.7 20.7 7.7C20.2 7.5 19.9 7.3 19.6 7.1Z" /></svg>
                    }
                    <div className={styles.info}>
                        {user.lastname} {user.firstname} {user.middlename}
                        <div>Тел.: <a href={"tel:" + user.phone} target="_blank">{user.phone}</a></div>
                    </div>
                </div>
                <div className={styles.icons}>
                    {/*props.type == 2*/ true &&
                        <span className={cn(styles.came, user.registred ? "" : styles.registred)} onClick={() => props.onCameClick(user.user_id)}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>
                                { user.registred ? "Участвовал" : "Не участвовал" }
                            </title><path d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" /></svg>
                        </span>
                    }
                    {(authStore.type == 1 && props.type == 1 ) &&
                        <span className={styles.delete} onClick={() => props.onUnsubscribeClick(user.user_id)}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>Удалить</title><path d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12L20 6.91Z" /></svg>
                        </span>
                    }
                </div>
            </div>
        )
    });

    return (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            centered
            backdrop="static"
            keyboard={false} >

            <div>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Участники
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {props.subscribers.length
                        ? list
                        : <div className="text-center">Нет участников</div>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button size="sm" variant="primary" onClick={props.handleClose}>Закрыть</Button>
                </Modal.Footer>
            </div>
        </Modal>
    );
};

export default observer(EventSubscribers);
