import log from "loglevel";
import { toast } from "react-toastify";
import { makeAutoObservable } from "mobx";

import { RootStore } from "./root-store";
import Api from "@api/api";


class ReviewStore {

    private rootStore: RootStore;

    private _reviews = [];

    get reviews() {
        return this._reviews;
    }
    set reviews(val) {
        this._reviews = val;
    }


    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async getReviews() {
        log.debug("getReviews");
        try {
            const res = await Api.reviews();
            log.debug(res);
            if (res.data.status === 'OK') {
                this.reviews = res.data.reviews;
            }
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async moderate(review_id: number, state: number) {
        log.debug("moderate review_id", review_id);
        try {
            const res = await Api.moderation_review(review_id, state);
            log.debug(res);
            if (res.data.status === 'OK') {
                state
                    ? toast.success("Одобрен")
                    : toast.error("Отклонен");
                await this.getReviews();
            }
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
        }
    }

}

export default ReviewStore;