let Menu = [
    {
        icon: 'home',
        link: '/main',
        title: 'Главная',
        admin: 1
    },
    {
        icon: 'users',
        link: '/moderation',
        title: 'Участники',
        admin: 1
    },
    {
        icon: 'star',
        link: '/reviews',
        title: 'Отзывы',
        admin: 1
    },
    {
        icon: 'cast',
        link: '/trainings',
        title: 'Мероприятия',
        admin: 0
    }
];

export default Menu;