import log from "loglevel";
import { observer } from "mobx-react-lite";
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { toJS } from "mobx";

import "./calendar.scss";

export type CalendarProps = {
    class?: string
    events: any[]
    onDateClick: any
    onDateSelected?: any
}

const Calendar = (props: CalendarProps) => {

    let datepicker: any = null;
    const ref = useRef(null);

    useEffect(() =>{
        if (!datepicker) {
            initCalendar(ref.current);
        }
    }, []);

    useEffect(() =>{
        if (props.events) {
            initCalendar(ref.current);
        }
    }, [ props.events ]);

    const formatDate = (stringDate: string) => {
        const date = new Date(stringDate);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    }

    const initCalendar = (el: any) => {
        el.innerHTML = "";
        if (datepicker) {
            datepicker.destroy();
        }
        // @ts-ignore
        datepicker = new window.Datepicker(el, {
            language: 'ru',
            format: 'yyyy-mm-dd',
            //minDate: moment().format("YYYY-MM-DD"),
            beforeShowDay: (date: string) => {
                let formatedDate = formatDate(date);
                let d = new Date(date);
                let dayName = d.toString().split(' ')[0];
                const day = ('0' + new Date(date).getDate()).slice(-2);
                let findedDateInfo = props.events?.find((item: any) => item.date1 === formatedDate);
                if (findedDateInfo) {
                    const classess = findedDateInfo.buy ? "day-has-event day-buy " + dayName : "day-has-event " + dayName;
                    let time = "";
                    let place = "";
                    if (findedDateInfo.place) {
                        place = `<span class="calendar-popup-place"><b>Место проведения:</b> ${findedDateInfo.place}</span> `;
                    }
                    if (findedDateInfo.time) {
                        time = `<span class="calendar-popup-time"><b>Время проведения мероприятия:</b> ${findedDateInfo.time}</span> `;
                    }

                    return {
                        classes: classess,
                        content: day
                    };
                } else {
                    return dayName;
                }
            },
        });
        el.addEventListener('click', (e: any) => {
            console.log("click", datepicker.getDate('yyyy-mm-dd'));
            props.onDateClick(datepicker.getDate('yyyy-mm-dd'));
        });
        el.addEventListener('changeDate', (e: any) => {
            console.log("changeDate", datepicker.getDate('yyyy-mm-dd'));
            if (props.onDateSelected) {
                props.onDateSelected(datepicker.getDate('yyyy-mm-dd'));
            }
        });
    }

    return (
        <div className="calendar mb-3" ref={ref} />
    );
};

export default observer(Calendar);
