import mitt from 'mitt';

import MainStore from "@store/main-store";
import AuthStore from "@store/auth-store";
import LoginStore from "@store/login-store";
import ModerationStore from "@store/moderation-store";
import TrainingStore from "@store/training-store";
import ReviewStore from "@store/review-store";

export class RootStore {

    // эмиттер для шины сообщений
    emitter = mitt();

    mainStore       = new MainStore(this);
    authStore       = new AuthStore(this);
    loginStore      = new LoginStore(this);
    moderationStore = new ModerationStore(this);
    trainingStore   = new TrainingStore(this);
    reviewStore     = new ReviewStore(this);

}
