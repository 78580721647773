import log from "loglevel";
import { instance } from "./service";

class Auth {

    login(email: string, password: string) {
        log.debug("login email:" + email + " password:" + password);
        return instance.post("/auth", {
            email: email,
            password: password
        });
    }

    user() {
        log.debug("user");
        return instance.get("/user");
    }

    reset() {
        log.debug("reset");
        return instance.get("/reset");
    }

}

export default new Auth();
