import log from "loglevel";
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { FloatingLabel, Form } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import styles from "./event-modal.module.scss";


export type EventModalProps = {
    class?: string
    show: boolean
    event: any
    handleClose: any
    onChangeField: any
    onDeleteClick: any
    onSaveClick: any
}

const EventModal = (props: EventModalProps) => {

    const dateFormat = () => {
        return moment(props.event?.date).format('YYYY-MM-DD');
    }

    const onSubmit = (e: any) => {
        e.preventDefault();
        log.debug("onSubmit");
        props.onSaveClick();
    }

    return (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            centered
            backdrop="static"
            keyboard={false}
        >
            <Form onSubmit={onSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {props.event?.event_id ? "Редактирование" : "Добавление"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <FloatingLabel
                        label="Название"
                        className="mb-1" >
                        <Form.Control
                            type="text"
                            value={props.event?.title}
                            onChange={(e: any) => props.onChangeField("title", e.target.value)}
                            required
                        />
                    </FloatingLabel>
                    <FloatingLabel
                        label={props.event?.type_id === 1 ? "Место проведения" : "Ссылка"}
                        className="mb-1" >
                        <Form.Control
                            type="text"
                            value={props.event?.place}
                            onChange={(e: any) => props.onChangeField("place", e.target.value)}
                            required />
                    </FloatingLabel>
                    <FloatingLabel
                        label="Дата"
                        className="mb-1" >
                        <Form.Control
                            className={styles.date}
                            type="date"
                            value={dateFormat()}
                            onChange={(e: any) => props.onChangeField("date", e.target.value)}
                            required />
                    </FloatingLabel>
                    <FloatingLabel
                        label="Время"
                        className="mb-1" >
                        <Form.Control
                            type="text"
                            value={props.event?.time}
                            onChange={(e: any) => props.onChangeField("time", e.target.value)}
                            required />
                    </FloatingLabel>
                    <FloatingLabel
                        label="Примечания"
                        className="mb-1 d-none" >
                        <Form.Control
                            className={styles.rem}
                            as="textarea"
                            type="text"
                            value={props.event?.rem}
                            onChange={(e: any) => props.onChangeField("rem", e.target.value)} />
                    </FloatingLabel>

                </Modal.Body>
                <Modal.Footer className={props.event?.event_id > 0 ? "d-flex justify-content-between" : ""}>
                    {props.event?.event_id > 0 &&
                        <Button size="sm" variant="danger" onClick={props.onDeleteClick}>Удалить</Button>
                    }
                    <Button size="sm" variant="primary" type="submit">Сохранить</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default observer(EventModal);
