import React from 'react';
import Sidebar from "@components/sidebar/sidebar";
import Navbar from "@components/navbar/navbar";
import Content from "@components/content/content";
import Footer from "@components/footer/footer";

const Index = () => {
    return (
        <div className="App wrapper">
            <Sidebar />
            <div className="main">
                <Navbar />
                <Content />
                <Footer />
            </div>
        </div>
    );
};

export default Index;
