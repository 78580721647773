import log from "loglevel";
import cn from "classnames";
import React, {useEffect, useState} from 'react';
import { observer } from "mobx-react-lite";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { QrScanner } from '@yudiel/react-qr-scanner';

import styles from "./event-register.module.scss";
import {useStore} from "@hooks/use-store";


export type EventRegisterProps = {
    class?: string
    show: boolean
    handleClose: any
    stopDecoding: boolean
}

const EventRegister = (props: EventRegisterProps) => {

    const { trainingStore } = useStore();

    const [ stopDecoding, setStopDecoding ] = useState(props.stopDecoding);
    const [ info, setInfo ]: any = useState();
    const [ locked, setLocked ]: any = useState(false);

    useEffect(() =>{
        setStopDecoding(props.stopDecoding);
        setLocked(false);
    }, [ props.stopDecoding ]);

    const onDecode = async (result: string) => {
        if (!locked) {
            const res = await trainingStore.findUser(result, trainingStore.event.event_id);
            console.log(111111111111111111111111, res);
            setLocked(true);
            setInfo(res);
            setTimeout(() => {
                setLocked(false);
            }, 3000);
        }
    }

    const onDecodeError = (error: any) => {
        console.log(error?.message)
    }

    const userInfo = () => {
        if (info && info.user) {
            return <div className={styles.box}>
                {info.user.lastname} {info.user.firstname} {info.user.middlename}
            </div>;
        } else {
            //return "Не найден";
        }
    }

    const eventInfo = () => {
        if (info) {
            if (info.state) {
                return <div className={cn(styles.box, styles.success)}>
                    Зарегистрирован
                </div>;
            } else {
                return <div className={cn(styles.box, styles.error)}>
                    {info.message}
                </div>;
            }
        }
    }

    return (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            centered
            backdrop="static"
            keyboard={false} >

            <div>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Регистрация
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className={styles.scanner}>
                        <div className={styles.scanner_container}>
                            <QrScanner
                                onDecode={onDecode}
                                onError={onDecodeError}
                                stopDecoding={stopDecoding}
                            />
                        </div>
                        <div className={styles.messages}>
                            {userInfo()}
                            {eventInfo()}
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button size="sm" variant="primary" onClick={props.handleClose}>Закрыть</Button>
                </Modal.Footer>
            </div>
        </Modal>
    );
};

export default observer(EventRegister);
