import log from "loglevel";
import React, { useEffect } from 'react';
import {Col, Container, Form, Row} from "react-bootstrap";
import { toJS } from "mobx";
import { useStore } from "@hooks/use-store";
import { observer } from "mobx-react-lite";

import Calendar from "./event-calendar/calendar";

import EventModal from "@views/trainings/event-modal/event-modal";
import EventDetails from "@views/trainings/event-details/event-details";
import EventSubscribers from "@views/trainings/event-subscribers/event-subscribers";
import EventRegister from "@views/trainings/event-register/event-register";

import styles from "./training.module.scss";


const Trainings = () => {

    const { trainingStore, authStore } = useStore();

    useEffect(() =>{
        trainingStore.getEvents().then();
    }, []);


    const onChangeType = async (e: any) => {
        log.debug("onChangeType", e.target.value);
        trainingStore.type = e.target.value;
        trainingStore.event = null;
        await trainingStore.getEvents();
    }

    const onAddEvent = (e: any) => {
        log.debug("onAddEvent", trainingStore.selectedData);
        trainingStore.event = {
            event_id: 0,
            type_id: trainingStore.type,
            date: trainingStore.selectedData
        };
        trainingStore.modalShow = true;
    }

    const onEditClick = (e: any) => {
        log.debug("onEditClick");
        trainingStore.modalShow = true;
    }

    const onSubscribersClick = () => {
        log.debug("onSubscribersClick", toJS(trainingStore.event));
        trainingStore.modalSubscribersShow = true;
    }

    const onDateSelected = async (date: string) => {
        log.debug("onDateSelected date", date);
        trainingStore.selectedData = date;
        trainingStore.event = trainingStore.events.filter(event => event.date === date).pop();
        if (trainingStore.event) {
            await trainingStore.getSubscribers();
        }
    }

    const onDeleteClick = async () => {
        log.debug("onDeleteClick");
        await trainingStore.delete();
        await trainingStore.getEvents();
        trainingStore.modalShow = false;
    }

    const onSaveClick = async () => {
        log.debug("onSaveClick");
        const res = await trainingStore.save();
        if (res) {
            trainingStore.modalShow = false;
            await trainingStore.getEvents();
        }
    }

    const onChangeField = (field: string, val: string) => {
        log.debug(`onChangeField field:${field} val:${val}`);
        trainingStore.event[field] = val;
    }

    const handleClose = () => {
        log.debug("handleClose");
        trainingStore.modalShow = false;
    }

    const handleSubscribersClose = () => {
        log.debug("handleSubscribersClose");
        trainingStore.modalSubscribersShow = false;
    }

    const handleRegisterClose = () => {
        log.debug("handleRegisterClose");
        trainingStore.modalRegisterShow = false;
        trainingStore.stopDecoding = true;
    }

    const onUnsubscribeClick = async (user_id: number) => {
        log.debug("onUnsubscribeClick user_id", user_id);
        await trainingStore.unsubscribe(user_id);
        await trainingStore.getSubscribers();
    }

    const onCameClick = async (user_id: number) => {
        log.debug("onCameClick user_id", user_id);
        await trainingStore.came(user_id);
        await trainingStore.getSubscribers();
    }

    const onRegisterClick = () => {
        log.debug("onRegisterClick", toJS(trainingStore.event));
        trainingStore.modalRegisterShow = true;
        trainingStore.stopDecoding = false;
    }


    return (
        <>
            <div>
                <Row className="mb-3">
                    <Col>
                        <Form.Select size="lg" className={styles.type} onChange={onChangeType}>
                            <option value={1}>Тренинги</option>
                            <option value={2}>Вебинары</option>
                        </Form.Select>
                    </Col>
                    <Col xs={2} className="d-flex justify-content-end">
                        {authStore.type == 1 &&
                            <button
                                className={styles.add_button}
                                title="Добавить мероприятие"
                                onClick={onAddEvent}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"/>
                                </svg>
                            </button>
                        }
                    </Col>
                </Row>
                <div className="d-flex justify-content-center">
                    <Calendar
                        events={trainingStore.events}
                        onDateClick={onDateSelected}
                    />
                </div>

                {trainingStore.event?.event_id > 0 &&
                    <EventDetails
                        event={trainingStore.event}
                        onEditClick={onEditClick}
                        onSubscribersClick={onSubscribersClick}
                        onRegisterClick={onRegisterClick}
                        subscribers={trainingStore.subscribers}
                    />
                }
            </div>

            <EventModal
                show={trainingStore.modalShow}
                event={trainingStore.event}
                handleClose={handleClose}
                onChangeField={onChangeField}
                onDeleteClick={onDeleteClick}
                onSaveClick={onSaveClick}
            />

            <EventSubscribers
                type={trainingStore.type}
                show={trainingStore.modalSubscribersShow}
                handleClose={handleSubscribersClose}
                subscribers={trainingStore.subscribers}
                onCameClick={onCameClick}
                onUnsubscribeClick={onUnsubscribeClick}
            />

            <EventRegister
                show={trainingStore.modalRegisterShow}
                stopDecoding={trainingStore.stopDecoding}
                handleClose={handleRegisterClose}
            />

        </>
    );

};

export default observer(Trainings);
